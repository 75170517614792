@font-face {
  font-family: "ithra";
  src: url("./fonts/ithraV3-bold.otf")  format("opentype");
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  font-family:  ithra, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

.center_content{
  margin-top: 400px;
}

.page__container{ 
  max-width: 500px;
  margin: 0 auto;
}

.content_container{ 
  position: fixed;
  background-color: white;
  bottom: 0;
  width: calc(100vw );
  border-radius: 24px 24px 0 0;
}
.email__input{
  
  width: calc(100% - 52px) ;
  margin:  0  0 25px 0;
  display: block;
  padding: 25px;
  border-radius: 10px;
  font-size: 16px;
  background-color:#D9D9D975;
  border: none; 
  border: 1px solid #F0F0F2;
  

}
.login__text{ 
  text-align: center;
  font-size: 18px;
}
.content_wrapper{ 
  padding: 25px;
}
::placeholder {
  /* color: var(--placeholder,  #fff); */
}
.input__title{
color: #0D2937;
  text-align: left;
  font-size: 20px;
  font-weight: 700;
}
.submit__btn{ 
  margin: 0 0;
  font-size: 20px;
  border: none ;
  background-color: #EF6346;
  padding: 15px 30px;
  border-radius: 10px;
display: block;
cursor: pointer;
color: white; 
 width: calc(100% ) ;


}

.center__container{ 
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative; 
    z-index: 2;
    
}
.top_margin{
  margin-top: 100px;
}
.waiting__title{
  display: block;
  color: #0D2937;
    text-align: center;
  font-size: 30px;
}
.waiting__loading{
  width: fit-content;
  margin: 0 auto;
}

.title{ 
  display: block;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}
.number{ 
  display: block;
  text-align: center;
  font-size: 48px;
  /* margin-top: -55px; */
  font-weight: 700;
  z-index: 2;
  margin-top: -20px;
  margin-bottom: 20px;
}
.footer{ 
  width: 100%;
  max-width: 500px;
  position: absolute;
  bottom:-5px;
  margin: 0 auto;
  margin-left: auto;
margin-right: auto;
left: 0;
right: 0;
z-index: 0;
pointer-events: none ;

}
.header{ 
  position: absolute;
  top:0;
  width: 100%;

}
.footer__image{width: 100%;
margin-bottom: -10px;}
.header__image{
  width: 100%;
}
.header__image__container{ 
  max-width: 300px;
  margin: 40px auto 0 auto;

}
.hackyard__header__image__container{ 
  max-width: 240px;
  margin: 60px auto 0 auto;
}

.question__btn__container{
  width: 50px;
  margin: 0 -90px 0 auto ;
}
.question__section{
  max-width: 500px;
  /* margin-top: -90px; */
  z-index: 10;
  position: relative;
  width: fit-content;
  margin-left: auto;
}
.question__icon{
  width: 100%;
  fill: #fff;
  z-index: 9;

}
.popup__remove{
  width: fit-content;
  color: white;
  font-weight: 700;
  font-size: 20px;
  margin: 30px 0 -70px auto;
  padding-top: 20px;
  padding-right: 30px;

}

.popup__container{
  max-width: 500px;
  width: 80%;
  margin: 0 auto;
  padding: 0;
  background-color: white;
  border-radius: 34px;
  padding-left: 30px;
  padding-bottom: 50px;
  height: fit-content;
  box-shadow: 0px 7px 40px rgba(0, 0, 0, 0.25);
  -webkit-animation: fadeIn 150ms ease-in  ;
  -moz-animation: fadeIn 150ms ease-in  ;
  animation: fadeIn 150ms ease-in  ;
  display: block;
  position: relative;
  margin-top: 30px;
  padding-right: 30px;
  z-index: 1000;
}

.notfound__title{ 

  background: linear-gradient(271deg, #1301F6 9.4%, #000 90.26%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
margin: 0 auto;
font-size: 35px;
margin-top: -50px;

}
@keyframes fadeIn {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

.questions__title{ 
  display: block;
  color: #0D2937;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.question__content{
  color: #EF6346;
  font-weight: 600;
  font-size: 20px;
  text-align: left;
  margin-bottom: 15px;
}
.numbers__container{
  display: flex;
  justify-content: center;
}

.number__container{
  margin: 0 30px ;
  /* margin-top: -220px; */
}
.more__icon__container{ 
  width: 80px;
  margin: 0 auto;
  /* margin-top: -30px; */
  margin-bottom: -40px;
  padding-right: 40px;
}
.more__icon__container> img {
  width: 100%; 
} 
.questions__container{
  max-height: 215px;
  overflow-y: scroll;
  margin-bottom: 20px;
}

.questions__container {
  scrollbar-width: thin; /* Standard for Firefox */
  scrollbar-color: #4e4e4e #0000001C; /* Thumb and track color */
}

/* For WebKit-based browsers (Chrome, Safari, Edge) */
.questions__container::-webkit-scrollbar {
  width: 7px;
  height: 7px; /* Ensures it works for horizontal scrolling too */
}

/* Track (background) */
.questions__container::-webkit-scrollbar-track {
  background-color: #0000001C;
  border-radius: 10px;
}

/* Scrollbar Thumb (the draggable part) */
.questions__container::-webkit-scrollbar-thumb {
  background: #4e4e4e;
  border-radius: 5px;
}

/* Ensure the thumb is visible in Safari */
.questions__container::-webkit-scrollbar-thumb:vertical {
  min-height: 20px; /* Prevents it from disappearing */
}

.questions__container::-webkit-scrollbar-thumb:horizontal {
  min-width: 20px;
}

/* Safari fix for macOS where scrollbars are hidden by default */
.questions__container {
  overflow: auto;
  -webkit-overflow-scrolling: touch; /* Enables smooth scrolling on iOS */
}

.App{
  position:fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}